import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDetail } from "../store/actions/users/users.actions";

export const useFeatureAccess = (requiredFeature) => {
  const { user } = useSelector((state) => state.userDetails);
  const { user: auth } = useSelector((state) => state.auth);

  const history = useNavigate();
  const { free_mode_count } = useSelector((state) => state.userFreeModeCount);
  const { free_mode_sqft_count } = useSelector((state) => state.userFreeModeCount);

  let hasFeatureAccess;

  hasFeatureAccess = (auth?.features?.includes(requiredFeature) && auth?.subscriptions?.length > 0) || free_mode_count < 10;

  let hasSqftFeatureAccess = (auth?.features?.includes(requiredFeature) && auth?.subscriptions?.length > 0) || free_mode_sqft_count < 20;

  const enforceAccess = () => {
    if (!hasFeatureAccess || free_mode_count > 10) {
      history("/pricing");
    }
  };
  // console.log(
  //   "hasFeatureAccess",
  //   hasFeatureAccess,
  //   free_mode_count,
  //   " user?.features.includes(requiredFeature) ",
  //   user?.features.includes(requiredFeature),
  //   "requiredFeature",
  //   requiredFeature,
  //   "user?.features",
  //   user
  // );

  return { hasFeatureAccess, enforceAccess, hasSqftFeatureAccess };
};
