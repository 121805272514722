import React, { useState } from "react";
import PropTypes from "prop-types";
import FavouriteIcon from "../../../assets/icons/FavouriteIcon";
import LocationIcon from "../../../assets/icons/LocationIcon";
import DirectionIcon from "../../../assets/icons/DirectionIcon";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Link } from "react-router-dom";
import FavouriteStrokeIcon from "../../../assets/icons/FavouriteStrokeIcon";
import DislikeIcon from "../../../assets/icons/DislikeIcon";
import Modal from "../../../components/modal/Modal";
import SendEmailForm from "../../../components/forms/sendEmailForm/SendEmailForm";
import Button from "../../../components/button/Button";
import "./ProjectDataCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faClock,
  faHeart,
  faHardHat,
  faHelmetSafety,
  faUser,
  faEnvelope,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
dayjs.extend(relativeTime);

const sortItems = [
  {
    id: 1,
    key: "last_modified_date",
    value: "updated",
  },
  {
    id: 2,
    key: "created_date",
    value: "newest",
  },
  {
    id: 3,
    key: "bid_due_date",
    value: "Bid due date",
  },
  {
    id: 4,
    key: "sf_size",
    value: "Square Feet",
  },
];

const ProjectDataCard = ({
  id,
  name,
  address,
  city,
  state_short,
  created_date,
  account_name,
  sf_size,
  url_slug,
  isFavourite,
  handleArchive,
  handleFavourite,
  last_modified_date,
  handleProjectDetailCLick,
  authenticatedUser,
  isHidden,
  isMainCheckboxChecked,
  selectedProjects,
  onProjectSelection,
  no_of_contacts,
  no_of_email_sent,
  last_email_sent,
  tags,
  units,
}) => {
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedSortItem, setSelectedSortItem] = useState();
  const [selectCheckBox, setSelectCheckBox] = useState(false);

  const handleSelectedSortItem = (itemId) => {
    const item = sortItems.find((item) => item.id === itemId);
    setSelectedSortItem(item.value);
  };

  const handleShowEmailModal = (e) => {
    setShowEmailModal(true);
    e.stopPropagation();
    e.preventDefault();
  };
  const handleSetSelectedCheckBox = (id, e) => {
    onProjectSelection(id, e.target.checked);
    setSelectCheckBox(!selectCheckBox);
  };

  const fullAddress = `${city}, ${state_short}`;
  const duration = dayjs(last_modified_date).fromNow();
  const handleSelectCheckBox = () => {
    setSelectCheckBox((prevChecked) => !prevChecked);
  };

  const tagsHelperText = {
    "Updated this week": "New information added in the last 7 days",
    "New this week": "Created within last 7 days",
    "Plans Drawings": "Architectural plans available for download",
    "Large area": "Between 150,001 and 500,000 SQFT",
    "Extra large area": "Between 500,001 and 3,800,000 SQFT",
    "Medium area": "Between 31,001 and 150,000 SQFT",
    "Extra small area": "Between 1 and 4,000 SQFT",
    "Small area": "Between 4,001 and 31,000 SQFT",
    "Pre construction":
      "Two weeks before the pre-construction bid due date and two weeks after",
    "Breaking ground now":
      "Sixty days before and sixty days after the estimated ground breaking date",
    "Completing soon": "Sixty days prior to and following the completion date",
    "Under construction":
      "From 61 days post-groundbreaking to 61 days before completion, including erecting, installing, and assembling structural, mechanical, and architectural components.",
  };

  return (
    <div className="d-flex flex-column board-list-card bg-white py-4">
      {/* // <div className="bg-white col py-4"> */}

      <Link
        to={`/project_board/${url_slug}`}
        className="text-decoration-none text-reset flex-grow-1"
        onClick={(e) =>
          handleProjectDetailCLick(e, `/project_board/${url_slug}`)
        }
      >
        <div className="d-flex justify-content-between mb-4">
          <div className="text-start project-name-details flex-grow-1">
            <div className="d-flex justify-content-between mb-3">
              <div className="flex-grow-1 text-start me-4 fw-bold text-13 project-name">
                {name}
              </div>
              {!isHidden && (
                <div className="svg-container">
                  {isFavourite ? (
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="cursor-pointer"
                      size="xl"
                      style={{ color: "#0d6efd" }}
                      onClick={(e) => handleFavourite(e, "unfavourite", id)}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faHeart}
                      className="cursor-pointer"
                      size="xl"
                      style={{
                        color: "#fff",
                        stroke: "#0d6efd",
                        strokeWidth: 50,
                      }}
                      onClick={(e) => handleFavourite(e, "favourite", id)}
                    />
                  )}
                </div>
              )}
            </div>

            <p className="text-10">
              By{" "}
              {authenticatedUser ? (
                <span className="blue-100">{account_name}</span>
              ) : (
                "..."
              )}
            </p>
          </div>
        </div>
        <div className="location-div col">
          <div className="d-flex flex-wrap text-start flex-column ">
            <div className="d-flex align-items-center me-3 mb-2 col">
              <DirectionIcon
                fill="#333333"
                width="14"
                height="14"
                className="me-2"
              />
              <p className="mb-0 text-13">
                {sf_size && sf_size >= 0 ? `${sf_size?.toLocaleString()} SQFT` : "- SQFT"}
              </p>
            </div>
            {units && (
              <div className="d-flex align-items-center mb-2  col">
                <p className="mb-0 text-13">
                  {`${units.toLocaleString()} Units`}
                </p>
              </div>
            )}
          </div>
          <div className="d-flex flex-wrap mt-2">
            {tags?.split(",").map((tag, index) => (
              <div
                key={index}
                className="p-2 m-1"
                style={{ background: "#70B2F0", borderRadius: "10px" }}
              >
                {tag === "Union requirements" || tag === "Davis Bacon" ? (
                  <div>{tag}</div>
                ) : (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip className="tooltip">
                        {tagsHelperText[tag]}
                      </Tooltip>
                    }
                  >
                    <div>{tag}</div>
                  </OverlayTrigger>
                )}
              </div>
            ))}
          </div>
          <div className="d-flex text-start align-items-center mb-3 mt-2">
            <LocationIcon
              width="14"
              height="14"
              fill="#333333"
              className="me-3"
            />
            <p className="mb-0 text-13">{fullAddress}</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 text-start">
            {/* <DislikeIcon
              width="24"
              height="24"
              handleClick={(e) => handleArchive(e, id)}
              className="me-3 cursor-pointer svg-archive"
              fill="currentColor"
            /> */}
            {/* <FontAwesomeIcon
              icon={faHardHat}
              className=""
              size="xl"
              style={{ color: "#0d6efd" }}
            /> */}
          </div>
          <div className="d-flex align-items-center">
            {/* <Button
              customClassName="btn-outline-secondary-intel btn-small"
              onClick={(e) => handleShowEmailModal(e)}
            >
              Check Availability
            </Button> */}
            <Modal
              title="New message"
              show={showEmailModal}
              onCloseModal={() => setShowEmailModal(false)}
            >
              <SendEmailForm
                selectedSortItem={selectedSortItem}
                sortItems={sortItems}
                handleSelectedSortItem={handleSelectedSortItem}
              />
            </Modal>
          </div>
        </div>
      </Link>
      <Row className="justify-content-md-between" style={{ height: "10%" }}>
        <Col className="col-md-2 d-flex flex-row col-3">
          {no_of_contacts > 0 && (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip className="tooltip"># of Decision Makers</Tooltip>
              }
            >
              <div className="d-flex">
                <FontAwesomeIcon icon={faUser} size="lg" />
                <span className="fw-bold mx-2">{no_of_contacts}</span>
              </div>
            </OverlayTrigger>
          )}
          {no_of_email_sent > 0 && (
            <div className="d-flex">
              <FontAwesomeIcon icon={faEnvelope} size="lg" />
              <span className="fw-bold mx-2">{no_of_email_sent}</span>
            </div>
          )}
        </Col>
        {/*{(no_of_email_sent > 0 || no_of_contacts > 0) && (*/}
        {/*  <Form.Group*/}
        {/*    as={Col}*/}
        {/*    md={1}*/}
        {/*    className="mb-3 checkbox m-0 d-flex col-1"*/}
        {/*    controlId={`project-${id}`}*/}
        {/*  >*/}
        {/*    <Form.Check*/}
        {/*      type="checkbox"*/}
        {/*      name={`project-${id}`}*/}
        {/*      className="fw-bolder ms-0"*/}
        {/*      checked={selectedProjects?.includes(id)}*/}
        {/*      onChange={(e) => onProjectSelection(id)}*/}
        {/*    />*/}
        {/*  </Form.Group>*/}
        {/*)}*/}
      </Row>
      {/*<Row>*/}
      {/*  <Col>*/}
      {/*    <FontAwesomeIcon icon={faCalendar} />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </div>
  );
};

ProjectDataCard.defaultProps = {
  name: "Williams Square (Ameilia House & Hotel Fredericksburg...",
  address: "Fredericksburg, VA",
  city: "city",
  state_short: "IA",
  created_date: "2020-04-20T16:08:10-04:00",
  account_name: "Damilola Adekoya",
  sf_size: 54000,
  id: 1,
  isHidden: false,
};

ProjectDataCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state_short: PropTypes.string.isRequired,
  created_date: PropTypes.string.isRequired,
  account_name: PropTypes.string.isRequired,
  sf_size: PropTypes.number.isRequired,
};

export default ProjectDataCard;
